import React from 'react'
import Layout from 'layouts/en'
import { RecommendState } from './index.tw'
import { Languages } from 'i18n/config'

import i18n from 'i18n/en/i18n_recommend'
import 'styles/base.less'

const Recommend: React.FC = (props) => {
  return (
    <Layout {...props}>
      <RecommendState
        demoUrl="/api/client/user/router?demo=Retail"
        floatingLinkedUrl="mailto:contactus@sensorsdata.com"
        floatingIconUrl={require(`assets/images/product/recommend/position_phone.svg`).default}
        i18n={i18n}
        lang={Languages.EN}
      />
    </Layout>
  )
}

export default Recommend
